'use client'

import React from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, buttonVariants } from '@dbbs/tailwind-components'
import Link from 'next/link'
import Image from 'next/image'
import { Minus, Plus } from 'iconoir-react'
import Typography from '../../atoms/Typography'
import chunk from '../../../utils/chunk'
import { FooterStoryblok, NavigationGroupStoryblok } from '../../../storyblok/types'
import ConsentSettingsTrigger from '../../atoms/ConsentSettingsTrigger'
import GoodFirmsWidget from '../../atoms/GoodFirmsWidget'

const SocialNetworksIconsMap: { [key: string]: string } = {
  Clutch: '/social-network/clutch.svg',
  Instagram: '/social-network/instagram.svg',
  Twitter: '/social-network/twitter.svg',
  LinkedIn: '/social-network/linkedin.svg',
  Facebook: '/social-network/facebook.svg',
  Github: '/social-network/github.svg'
}

const SocialNetworksIconsAltMap: { [key: string]: string } = {
  Clutch: 'Clutch Icon',
  Instagram: 'Instagram Icon',
  Twitter: 'Twitter Icon',
  LinkedIn: 'LinkedIn Icon',
  Facebook: 'Facebook Icon',
  Github: 'Github Icon'
}

const AccordionHeader = ({ itemsGroup }: { itemsGroup: NavigationGroupStoryblok[] }) => (
  <div className="flex w-full gap-8 justify-between">
    {itemsGroup.map((item) => (
      <Typography
        key={item._uid}
        variant="link"
        className="w-72 text-start text-secondary-grey group-hover:text-primary"
      >
        {item.title}
      </Typography>
    ))}
  </div>
)
const AccordionBody = ({ itemsGroup }: { itemsGroup: NavigationGroupStoryblok[] }) => (
  <>
    {itemsGroup.map((item) => (
      <div key={item._uid} className="flex flex-col w-72 items-start gap-5">
        {item.links.map((link) => (
          <Link key={link._uid} className={buttonVariants({ variant: 'link', size: 'link' })} href={link.url || '#'}>
            {link.title}
          </Link>
        ))}
      </div>
    ))}
  </>
)

const renderAccordionItem = (data: NavigationGroupStoryblok[][]) =>
  data.map((item, index) => (
    <AccordionItem
      key={`accordion-item-${data.length}-${index}`}
      value={index.toString()}
      className="border-b border-line-grey flex flex-col group"
    >
      <AccordionTrigger
        className="py-4 lg:py-8 group-hover:text-primary [&[data-state=open]>.close-icon]:text-primary [&[data-state=open]>div>p]:text-primary"
        openIcon={Plus}
        closeIcon={Minus}
      >
        <AccordionHeader itemsGroup={item} />
      </AccordionTrigger>
      <AccordionContent className="flex justify-between mr-6 mb-8">
        <AccordionBody itemsGroup={item} />
      </AccordionContent>
    </AccordionItem>
  ))

const Footer: React.FC<FooterStoryblok> = ({ groups, address, email, phone, socialLinks, legalLinks, copyright }) => {
  const desktopData = chunk(groups, 3)
  const mobileData = chunk(groups, 1)

  return (
    <div className="container">
      <div role="contentinfo" className="flex flex-col gap-8 bg-white pb-10 px-4 lg:px-16">
        <Accordion type="multiple" className="hidden lg:block w-full border-t border-line-grey">
          {renderAccordionItem(desktopData)}
        </Accordion>
        <Accordion type="multiple" className="lg:hidden w-full border-t border-line-grey">
          {renderAccordionItem(mobileData)}
        </Accordion>
        <div className="flex flex-col lg:flex-row flex-nowrap justify-between gap-8">
          <div className="flex gap-8 flex-col">
            <Typography variant="link" className="text-secondary-grey">
              Contact
            </Typography>
            <div className="flex flex-col gap-6 lg:flex-row">
              <div className="flex flex-col items-start gap-4">
                <Link href={`tel:${phone}`} className={buttonVariants({ variant: 'link', size: 'link' })}>
                  <Typography>{phone}</Typography>
                </Link>
                <Link href={`mailto:${email}`} className={buttonVariants({ variant: 'link', size: 'link' })}>
                  <Typography>{email}</Typography>
                </Link>
                <Typography>{address}</Typography>
                <div className="flex justify-between self-center w-full sm:w-3/5 lg:self-auto lg:justify-normal lg:gap-4">
                  {socialLinks.map(({ link, title }, index) => (
                    <Link
                      href={link.url}
                      target={link.target}
                      key={`social-networks-${index}-${link.id}`}
                      className="hover:brightness-110"
                    >
                      {title || '' in SocialNetworksIconsMap ? (
                        <Image
                          src={SocialNetworksIconsMap[title || '']}
                          alt={SocialNetworksIconsAltMap[title || '']}
                          width={32}
                          height={32}
                          className="cursor-pointer"
                          unoptimized
                        />
                      ) : (
                        <Typography>{title}</Typography>
                      )}
                    </Link>
                  ))}
                </div>
                <div className="hidden lg:flex flex-col items-center gap-5 justify-end">
                  <div className="flex gap-6">
                    {legalLinks.map((item) => {
                      if (item.component === 'consentSettingsTrigger') {
                        return <ConsentSettingsTrigger key={item._uid} label={item.label} />
                      }

                      const { link, title } = item
                      return (
                        <Link
                          key={item._uid}
                          href={link.url}
                          className={buttonVariants({ variant: 'link', size: 'link' })}
                        >
                          {title}
                        </Link>
                      )
                    })}
                  </div>
                  <Typography className="text-secondary-grey">{copyright}</Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center lg:h-auto h-96">
            <GoodFirmsWidget className="w-[460px]" />
          </div>
          <div className="lg:hidden flex flex-col items-center gap-5 justify-end">
            <div className="flex gap-6">
              {legalLinks.map((item) => {
                if (item.component === 'consentSettingsTrigger') {
                  return <ConsentSettingsTrigger key={item._uid} label={item.label} />
                }

                const { link, title } = item
                return (
                  <Link key={item._uid} href={link.url} className={buttonVariants({ variant: 'link', size: 'link' })}>
                    {title}
                  </Link>
                )
              })}
            </div>
            <Typography className="text-secondary-grey">{copyright}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
