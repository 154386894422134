import { cn } from '@dbbs/tailwind-components'
import Script from 'next/script'
import React, { HTMLAttributes } from 'react'
import { InView } from 'react-intersection-observer'

const GoodFirmsWidget = (props: HTMLAttributes<HTMLDivElement>) => {
  const initWidget = () =>
    typeof window !== 'undefined' && window.GOODFIRMS ? window.GOODFIRMS && window.GOODFIRMS.Init() : null

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <>
          {inView ? (
            <>
              <Script
                src="https://assets.goodfirms.co/assets/js/widget.min.js"
                strategy="afterInteractive"
                onLoad={initWidget}
              />
              <div
                data-widget-type="goodfirms-widget-t2"
                data-widget-pattern="star-review"
                data-height="100%"
                data-company-id="147435"
                {...props}
                className={cn('goodfirm-widget', props.className)}
              />
            </>
          ) : (
            <div ref={ref} />
          )}
        </>
      )}
    </InView>
  )
}

export default GoodFirmsWidget
